import React, { useState } from "react";
import "./Portal.css";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import LazyLoad from "react-lazyload";

import { useSpring, animated } from "@react-spring/web";

const Portal = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [isRotated, setIsRotated] = useState(false);
  const [hoveredCard, setHoveredCard] = useState(null);

  const logoInsani = useSpring({
    transform: isRotated ? "rotate(360deg)" : "rotate(0deg)",
    config: { duration: 800 },
    cursor: "pointer",
  });

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
    setIsRotated(!isRotated);
  };

  const handleCardHover = (text) => {
    setHoveredCard(text);
  };

  const handleCardLeave = () => {
    setHoveredCard(null);
  };

  return (
    <div className="single-page-container text-center">
      {/* Main Content */}
      <main className="main">
        <section className="section">
          <div className="content">
            <h1 className="title">Welcome to Support System Directory</h1>
          </div>

          <div className="container">
            <animated.div
              className="logo-insani"
              onClick={toggleMenu}
              style={logoInsani}
            >
              <img
                src="/assets/logo-peduli-insani3-resize.webp"
                alt="logo"
                className="peduli-insani"
              />
            </animated.div>

            {menuVisible && (
              <div className="menu">
                <Card
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  className="inovy mx-auto"
                  onMouseEnter={() => handleCardHover("Inovy")}
                  onMouseLeave={handleCardLeave}
                >
                  <LazyLoad height={200} offset={100}>
                    <a
                      href="https://inovy.ykbut.co.id/inovy/"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="inline-inovy">
                        <div>
                          <img
                            variant="top"
                            src="/assets/inovy-resize.png"
                            className="card-img1"
                            alt="img-inovy"
                          />
                        </div>
                        <p className="p-inovy">INOVY</p>

                        {hoveredCard === "Inovy" && (
                          <div
                            className="box-inovy"
                            style={{ transform: "translate(-15%, -400%)" }}
                          >
                            <div className="hover-inovy">
                              <p className="p-hi" style={{ color: "#000" }}>
                                Portal Inovasi YKBUT
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </a>
                  </LazyLoad>
                </Card>

                <Card
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  className="boomer mx-auto"
                  onMouseEnter={() => handleCardHover("Boomer")}
                  onMouseLeave={handleCardLeave}
                >
                  <LazyLoad height={200} offset={100}>
                    <a
                      href="https://inovy.ykbut.co.id/boomer/"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="inline-boomer">
                        <div>
                          <img
                            variant="top"
                            className="card-img2"
                            src="/assets/BOOMER-resize.png"
                            alt="img-boomer"
                          />
                        </div>
                        <p className="p-boomer">Boomer</p>
                        {hoveredCard === "Boomer" && (
                          <div
                            className="box-boomer"
                            style={{ transform: "translate(50%, -150%)" }}
                          >
                            <div className="hover-boomer">
                              <p className="p-hb" style={{ color: "#000" }}>
                                Aplikasi Booking Ruang Meeting YKBUT & Kanitra
                                Group
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </a>
                  </LazyLoad>
                </Card>

                <Card
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  className="toca mx-auto"
                  onMouseEnter={() => handleCardHover("ToCA")}
                  onMouseLeave={handleCardLeave}
                >
                  <LazyLoad height={200} offset={100}>
                    <a
                      href="https://toca.ykbut.co.id/"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="inline-toca">
                        <div>
                          <img
                            variant="top"
                            className="card-img3"
                            src="/assets/ToCA2-resize.png"
                            alt="img-toca"
                          />
                        </div>
                        <p className="p-toca">ToCA</p>
                        {hoveredCard === "ToCA" && (
                          <div
                            className="box-toca"
                            style={{ transform: "translate(55%, -210%)" }}
                          >
                            <div className="hover-toca">
                              <p className="p-ht" style={{ color: "#000" }}>
                                Aplikasi Penilaian Culture
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </a>
                  </LazyLoad>
                </Card>

                <Card
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  className="wbs mx-auto"
                  onMouseEnter={() => handleCardHover("WBS")}
                  onMouseLeave={handleCardLeave}
                >
                  <LazyLoad height={200} offset={100}>
                    <a
                      href="https://ykbut.co.id/PedomanWhistleBlowerYKBUTR12024.pdf"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="inline-wbs">
                        <div>
                          <img
                            variant="top"
                            className="card-img4"
                            src="/assets/WBS-resize.png"
                            alt="img-wbs"
                          />
                        </div>
                        <p className="p-wbs">WBS</p>
                        {hoveredCard === "WBS" && (
                          <div
                            className="box-wbs"
                            style={{ transform: "translate(60%, -230%)" }}
                          >
                            <div className="hover-wbs">
                              <p className="p-hw" style={{ color: "#000" }}>
                                Panduan Pelaporan Pelanggaran
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </a>
                  </LazyLoad>
                </Card>

                <Card
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    transitionDuration: "100s",
                  }}
                  className="kopkar mx-auto"
                  onMouseEnter={() => handleCardHover("Kopkar")}
                  onMouseLeave={handleCardLeave}
                >
                  <LazyLoad height={200} offset={100}>
                    <a
                      href="https://kopkarkita.ykbut.co.id/"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="inline-kopkar">
                        <div>
                          <img
                            variant="top"
                            className="card-img5"
                            src="/assets/kopkar2-resize.png"
                            alt="img-kopkar"
                          />
                        </div>
                        <p className="p-kopkar">Kopkar YKBUT</p>
                        {hoveredCard === "Kopkar" && (
                          <div
                            className="box-kopkar"
                            style={{ transform: "translate(-20%, -300%)" }}
                          >
                            <div className="hover-kopkar">
                              <p className="p-hk" style={{ color: "#000" }}>
                                Koperasi Simpan Pinjam Karyawan YKBUT
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </a>
                  </LazyLoad>
                </Card>

                <Card
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  className="it mx-auto"
                  onMouseEnter={() => handleCardHover("It")}
                  onMouseLeave={handleCardLeave}
                >
                  <LazyLoad height={200} offset={100}>
                    <a
                      href="https://itsm.ykbut.co.id/scp/login.php"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="inline-it">
                        <div>
                          <img
                            variant="top"
                            className="card-img6"
                            src="/assets/it_helpdesk2-resize.png"
                            alt="img-it"
                          />
                        </div>
                        <p className="p-it">IT Helpdesk</p>
                        {hoveredCard === "It" && (
                          <div
                            className="box-it"
                            style={{ transform: "translate(-110%, -170%)" }}
                          >
                            <div className="hover-it">
                              <p className="p-hit" style={{ color: "#000" }}>
                                Ticketing Layanan IT YKBUT
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </a>
                  </LazyLoad>
                </Card>

                <Card
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  className="cla mx-auto"
                  onMouseEnter={() => handleCardHover("CLA")}
                  onMouseLeave={handleCardLeave}
                >
                  <LazyLoad height={200} offset={100}>
                    <a
                      href="https://cla.ykbut.co.id/web/login"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="inline-cla">
                        <div>
                          <img
                            variant="top"
                            className="card-img7"
                            src="/assets/cla2-resize.png"
                            alt="img-cla"
                          />
                        </div>
                        <p className="p-cla">CLA</p>
                        {hoveredCard === "CLA" && (
                          <div
                            className="box-cla"
                            style={{ transform: "translate(-115%, -160%)" }}
                          >
                            <div className="hover-cla">
                              <p className="p-hc" style={{ color: "#000" }}>
                                Aplikasi Monitoring Target Entitas
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </a>
                  </LazyLoad>
                </Card>

                <Card
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  className="smartco mx-auto"
                  onMouseEnter={() => handleCardHover("Smartco")}
                  onMouseLeave={handleCardLeave}
                >
                  <LazyLoad height={200} offset={100}>
                    <a
                      href="https://smartco.ykbut.co.id/"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="inline-smartco">
                        <div>
                          <img
                            variant="top"
                            className="card-img8"
                            src="/assets/smartco1-resize.png"
                            alt="img-smartco"
                          />
                        </div>
                        <p className="p-smartco">Smartco</p>
                        {hoveredCard === "Smartco" && (
                          <div
                            className="box-smartco"
                            style={{ transform: "translate(-120%, -180%)" }}
                          >
                            <div className="hover-smartco">
                              <p className="p-hs" style={{ color: "#000" }}>
                                Aplikasi Canvasing BusDev
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </a>
                  </LazyLoad>
                </Card>
              </div>
            )}
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="social-icons">
              <LazyLoad height={200} offset={100}>
                <a
                  href="https://m.facebook.com/ykbutofficial/"
                  className="text-link"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    size="2x"
                    style={{ color: "#ffffff" }}
                    className="icon"
                  />
                </a>
              </LazyLoad>
              <LazyLoad height={200} offset={100}>
                <a
                  href="https://www.youtube.com/@ykbutofficial3370"
                  className="text-link"
                >
                  <FontAwesomeIcon
                    icon={faYoutube}
                    size="2x"
                    style={{ color: "#ffffff" }}
                  />
                </a>
              </LazyLoad>
              <LazyLoad height={200} offset={100}>
                <a
                  href="https://www.instagram.com/ykbutofficial/"
                  className="text-link"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="2x"
                    style={{ color: "#ffffff" }}
                  />
                </a>
              </LazyLoad>
              <span className="teks">@ykbutofficial</span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Portal;
