import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Portal from "./component/Portal.js";



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Portal/>}/>
       
      </Routes>
    </Router>
  
  );
}

export default App;
